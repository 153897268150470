<template>
  <div id="pageMarca">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg8 sm12 xs12>
          <marca-table></marca-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import MarcaTable from "@/components/marca/tableMarca"
export default {
  components: {
    MarcaTable
  }
}
</script>
